import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Login/login';
import LoginOK from './Login/loginOK';
import LoginKO from './Login/loginKO';
import Registro from './Registro/registro';
import Privacy from './Privacy/privacy';
import Eula from './eula/eula';

import './App.css';

axios.interceptors.request.use( request =>{
  /** 
   * Qui è possibile editare qualsiasi request in uscita
   * ad esempio aggiungere headers variable 
   * */ 

  let token = localStorage.getItem('token');
  if(token)
    request.headers['x-access-token'] =  token;

   return (request)
}, error => {
  /** 
   * Qui è possibile gestire centralmente tutti gli errori
   * in spedizione di requests
   */

   console.log("Errore nella spedizione della request. E' possibile gestirla globalmente da App.js")
   // Rimando il controllo al componente locale
   return Promise.reject(error);
})

axios.interceptors.response.use( response =>{
  /**
   * Qui è possibile editare qualsiasi response in entrata
   * */   
 
 
   return (response)
}, error => {
  /** 
   * Qui è possibile gestire centralmente tutti gli errori
   * in ricezione di response
   */
  if(error.toString().indexOf("status code 401") !== -1  || error.toString().indexOf("status code 403") !==-1 ){
    console.log("Rimuovo il token!")
    localStorage.removeItem('token');
   }

   if(error.message === 'Network Error'){
    localStorage.setItem('offline',true);
    console.log("Trovato Network error centrale, vado in OFFLINE : ")
    window.location = '/offline'   
 }

   // Rimando il controllo al componente locale
   return Promise.reject(error);
})



class App extends Component {



  render(){
    return(
      <Router>
        <Switch>
          <Route exact path="/"><Login /></Route>
          <Route exact path="/login"><Login /></Route>
          <Route exact path="/loginOK"><LoginOK /></Route>
          <Route exact path="/loginKO"><LoginKO /></Route>
          <Route path="/registro"><Registro /></Route>
          <Route exact path="/privacy"><Privacy /></Route>
          <Route exact path="/eula"><Eula /></Route>
        </Switch>
      </Router>

      
    )
  }
}

export default App;
