import React from 'react';
import {Button, Row, Col, Card, Container, Jumbotron} from 'react-bootstrap';

function Privacy() {

    return (
        <div>
            <Jumbotron className="Privacy">
            <h1>Registro conti</h1>
            <p>
                Informativa sulla privacy
            </p>
            </Jumbotron>

            <Container>
                <h2>Incepit</h2>
                <p>
                    Questo sito non offre un pubblico servizio, ma è destinato ad un uso privato. 
                    Gli utenti di questo servizio sono stati messi al corrente dell'utilizzo dei dati del presente servizio.
                </p>

                <h2>Regole generali per la compilazione della privacy</h2>
                <p>
                    Il presente documento di privacy policy, aggiornato con il Regolamento UE (GDPR) 2016/679 relativo 
                    al trattamento dei dati personali, così come con il D.Lgs 181/18 che modifica il D.Lgs 196/2003, 
                    regola le modalità di trattamento dei dati raccolti da un sito internet durante la 
                    navigazione da parte dell'utente.
                </p>

                <p>Esso ha lo scopo preciso di informare l'utente circa il trattamento dei suoi dati personali secondo 
                    quanto previsto dalla legge e dal recente Regolamento UE 679/2016, che ha modificato profondamente 
                    la disciplina.
                </p>

                <p>Un sito web deve avere un Titolare del trattamento dei dati (Data Controller). Il titolare del trattamento 
                    è colui che ha potere decisionale e organizzativo sul trattamento, oltre che decidere le modalità di trattamento 
                    dei dati ed è il responsabile nei confronti del garante della privacy. Si possono nominare anche due o più contitolari. 
                    In questo caso, è obbligatorio che l'utente sappia quali siano le competenze di ogni contitolare, attraverso un link 
                    che indica l'accordo tra gli stessi.
                </p>

                <p>Il titolare del trattamento, è affiancato dal Responsabile del Trattamento (Data Processor). 
                    Questa figura è colui che tratta i dati per conto del titolare del trattamento. Ciò significa, 
                    che sarà un soggetto vicino al titolare, dal quale riceve direttive su come gestire i dati. 
                    Il Responsabile del trattamento deve essere una figura competente in grado di soddisfare a pieno 
                    la sicurezza posta in essere dal Titolare del trattamento.
                </p>

                <p>A queste due figure, si affianca il Responsabile della protezione dati (Data Protection Officer DPO), 
                    il quale, nonostante venga nominato direttamente dal titolare, è comunque un soggetto indipendente da quest'ultimo. 
                    Il DPO, prima solamente facoltativo, è ora una figura a volte obbligatoria ai dell'art.37 del Regolamento (UE) 679/2016. 
                    In questo articolo vengono indicati i soggetti obbligati e quelli che ne sono esenti. In ogni caso, il DPO, detto RPD in Italiano, 
                    è un soggetto indipendente e tratta i dati con autonomia. Inoltre, è direttamente responsabile e comunica con il garante della privacy. 
                    In definitiva, la designazione del DPO riflette il nuovo approccio del GDPR, verso una responsabilizzazione del trattamento dei dati, 
                    essendo finalizzata a facilitare l'attuazione del regolamento da parte del titolare e del responsabile. Il ruolo del DPO è di 
                    tutelare i dati personali, non gli interessi del titolare del trattamento.
                </p>

                <p>Quindi, mentre il Responsabile del trattamento è una figura vicina al Titolare, il DPO è una figura molto più indipendente, 
                    la quale non può ne deve ricevere ordini dal titolare sull'effettiva protezione dei dati.
                </p>

                <p>
                    Tornando all'informativa, deve essere indicato anche il luogo dove verranno trattati i dati, 
                    che coincide con la sede del titolare del trattamento dei dati.
                </p>

                <p>Fondamentale è inserire anche le finalità del trattamento dei dati. Infatti, secondo la nuova normativa, 
                    i dati dovranno essere conservati per un periodo idoneo al raggiungimento delle finalità preposte dal sito, 
                    per poi essere cancellati. Quindi è obbligatorio che le finalità vengano indicate in maniera chiara e concisa 
                    all'interno dell'informativa.
                </p>

                <p>Nel documento devono anche essere indicarti i tipi di cookies che vengono utilizzati nella pagina web. 
                    I cookies sono brevi informazioni che possono essere salvate sul computer dell'utente quando il browser 
                    richiama un determinato sito web. Con essi il server invia informazioni che saranno rilette ed aggiornate 
                    ogni qual volta l'utente ritornerà sul sito.
                </p>
                
            </Container>
        </div>

        
    )

}

export default Privacy;