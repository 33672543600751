import React, { Component } from 'react';
import {Table, Row, Col, Card, Container, Form} from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faInfo } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

class Movimenti extends Component {

    state = {
        showDetails:false
    }

    szMovimenti = () => {

        let movimenti = this.props.movimenti
                .filter(c=> (c.idContoOrigine === this.props.contoSelezionato[0].idConto || c.idContoDestinazione === this.props.contoSelezionato[0].idConto) )
                .map(c =>{
                    let segno=1;
                    if(c.idContoOrigine === this.props.contoSelezionato[0].idConto)
                       segno=-1;
                    return(
                        <tr key={c.idMovimento}>
                            <td>{c.idMovimento}</td>
                            <td><Moment format="DD/MM/YYYY HH:mm:ss">{c.DataMovimento}</Moment></td>
                            <td>{c.Descrizione}</td>
                            <td>{c.Importo * segno}</td>
                        </tr>
                    )

                })     

        let table = 
            <Table table-responsive striped bordered hover size="sm" >
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Data</th>
                    <th>Descrizione</th>
                    <th>Importo €</th>
                    </tr>
                </thead>
                <tbody>
                    {movimenti}
                </tbody>
            </Table>

        let noMovimenti=<span>Nessun movimento presente per questo conto </span>

        if(movimenti.length>0)
            return(table)
        else
            return(noMovimenti)

    }


    spinner = () =>{
        return(
            <Loader
               type="Puff"
               color="#00BFFF"
               height={100}
               width={100}
               timeout={3000} //3 secs
       
            />)
    }

    dettaglioConto = () => {
        return(

            <Card className="DettaglioConto">
            <Card.Body>
                <Card.Title>Dettaglio Conto</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{this.props.contoSelezionato[0].Descrizione}</Card.Subtitle>
                <Card.Text>
                <dl class="row">
                                        <dt class="col-sm-3">IBAN</dt>
                                        <dd class="col-sm-9">{this.props.contoSelezionato[0].IBAN}</dd>

                                        <dt class="col-sm-3">ABI</dt>
                                        <dd class="col-sm-9">{this.props.contoSelezionato[0].ABI}</dd>      

                                        <dt class="col-sm-3">CAB</dt>
                                        <dd class="col-sm-9">{this.props.contoSelezionato[0].CAB}</dd>     

                                        <dt class="col-sm-3">Conto</dt>
                                        <dd class="col-sm-9">{this.props.contoSelezionato[0].NumeroConto}</dd>        

                                        <dt class="col-sm-3">BIC</dt>
                                        <dd class="col-sm-9">{this.props.contoSelezionato[0].BIC}</dd>                                                                                             
                                    </dl>
                </Card.Text>
            </Card.Body>
            </Card>

        )
    }

    swopDettaglioConto = () =>{
        this.setState({showDetails : !this.state.showDetails})
    }

    pageMovimenti = () =>{
        console.log(this.props.contoSelezionato)
        return(
            <Container>
                    <Row>
                        <Col><Row><FontAwesomeIcon  className="iconMark" icon={faInfo} color="purple" onClick={this.swopDettaglioConto}/><h3>{this.props.contoSelezionato[0].NomeConto}</h3></Row></Col>
                        <Col><h5>€{this.props.contoSelezionato[0].Totale}</h5></Col>
                    </Row>
                    {this.state.showDetails ? this.dettaglioConto() : null}

                <Row>
                    {this.szMovimenti()}
                </Row>         
            </Container>
        )        

    }

    optConti = () =>{
        let current=0;
        if(this.props.contoSelezionato[0]) current=this.props.contoSelezionato[0].idConto;

        return(
            this.props.conti.map(c => {
                if(current === c.idConto)
                    return(<option key={c.idConto} value={c.idConto} selected>{c.NomeConto}</option>)
                else
                    return(<option key={c.idConto} value={c.idConto}>{c.NomeConto}</option>)
            })
        )
    }

    fieldsForm = (e) => {
        console.log(e.target.value)
        this.props.selectConto(parseInt(e.target.value,10), true);
    }    

    render(){
        return(
            <div>
                <Row>
                    <Col md={8}><h1>Movimenti</h1></Col>
                    <Col>                 
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Seleziona un altro conto</Form.Label>
                            <Form.Control as="select" name="idContoDestinazione" onChange={this.fieldsForm}>
                                <option key="0" value="0">Seleziona un conto</option>
                                {this.optConti()}
                            </Form.Control>
                        </Form.Group>                        
                    </Col>
                </Row>
                
                {this.props.contoSelezionato.length > 0 ? this.pageMovimenti() : this.spinner() }
            </div>
        )
      }
}
  
export default Movimenti;