import React from 'react';
import { useLocation, Redirect } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }


function LoginOK() {

    localStorage.setItem('token', useQuery().get("token"));
    console.log("Nome : "+useQuery().get("nome"))

    const location = useLocation();

    return(
        <Redirect to={{ pathname: "/registro", state: { from: location } }} />
    )
}

export default LoginOK;