import React from 'react';
import {Button, Row, Col, Card, ListGroup, Table, Form, Container} from 'react-bootstrap';

function loginKO() {
    return(
        <div className="banner">    
            <div className="TextBackground">
                <h1>...oh oh qualcosa è andato storto</h1>
                <p>
                    Questo sito non offre un servizio pubblico, non è possibile creare account.
                    E' un servizio privato con invito. 
                </p>
                <p>
                    Se sei capitato quì per errore ti è sufficente chiudere questa finestra, non c'è alcun modo per poter
                    effettuare il login. Se invece ritieni di aver diritto ad accedere contrlolla quanto segue:                
                </p>
                <ul>
                    <li>Di effettuare il login con il tasto social button corretto</li>
                    <li>Che la tua email predefinita sia la prima in ordine nel tuo profilo social</li>
                </ul>
                <hr/>
                <p>
                    Se nonostante questi controlli continui a rivecere questo errore contatta l'amministratore del dominio (dovresti sapere chi è).
                </p>

            </div>        
            
        </div>
        
    )
}

export default loginKO;