
import React, {Component} from 'react';
import { Redirect } from "react-router-dom";
import {Alert, Row, Col, Card, Container, ListGroup} from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import Header from './Header/header';
import Conti from './Conti/conti';
import Movimenti from './Movimenti/movimenti';
import Inserisci from './Inserisci/inserisci';
import Logs from './Logs/logs';

import logo from '../images/Registro.png'

class Registro extends Component {


    state = {
        conti : [],
        tipiConto:[],
        movimenti:[],
        users:[],
        contoSelezionato:[],
        logs:[],
        serverInfo:{},
        alert:null,
        currentPage:"",
        redirectPage:null,
        app_name:null,
        app_version:null,
        app_build_date:null
    }    

    componentDidMount() {

        this.setState({app_name:process.env.REACT_APP_NAME, app_version:process.env.REACT_APP_VERSION, app_build_date:process.env.REACT_APP_DATEBUILD})
        
        this.getServerInfo()
        .then(info => this.setState({serverInfo:info.data}))
        .catch(error => console.log(error));

        this.getUsers()
        .then(users => this.setState({users:users.data.data}))
        .catch(error => console.log(error));

        this.getConti()
        .then(conti => this.setState({conti:conti.data.data}))
        .catch(error => console.log(error));

        this.getLogs()
        .then(logs => this.setState({logs:logs.data.data}))
        .catch(error => console.log(error));        

        this.getMovimenti()
        .then(movimenti => this.setState({movimenti:movimenti.data.data}))
        .catch(error => console.log(error));

        this.getTipiConto()
        .then(tipiConto => this.setState({tipiConto:tipiConto.data.data}))
        .catch(error => console.log(error));        

        let currentPage = window.location.href.substring(window.location.href.lastIndexOf("/")+1,window.location.href.length);
        this.setState({currentPage:currentPage});
    }

    getServerInfo = () =>{
        return new Promise( (fulfill, reject) =>{
            axios('info')
            .then( info => fulfill(info))
            .catch( error => reject(error))
          })        
    }

    getUsers = () => {
        return new Promise( (fulfill, reject) =>{
          axios('users')
          .then( users => fulfill(users))
          .catch( error => reject(error))
        })
    }      

    getConti = () => {
        return new Promise( (fulfill, reject) =>{
          axios('conti')
          .then( conti => fulfill(conti))
          .catch( error => reject(error))
        })
    }  
    
    getLogs = () => {
        return new Promise( (fulfill, reject) =>{
          axios('logs')
          .then( logs => fulfill(logs))
          .catch( error => reject(error))
        })
    }  

    getTipiConto = () => {
        return new Promise( (fulfill, reject) =>{
          axios('conti/tipo')
          .then( conti => fulfill(conti))
          .catch( error => reject(error))
        })
    }      

    getMovimenti = () => {
        return new Promise( (fulfill, reject) =>{
          axios('movimenti')
          .then( conti => fulfill(conti))
          .catch( error => reject(error))
        })
    }        

    addMovimento = (data) => {
        return new Promise ( (fulfill, reject) => {
            axios.post('movimenti',data)
            .then( conti => fulfill(conti))
            .catch( error => reject(error))
          })                    
    }



    toLogin = () => {
        return(
            <Redirect  />
        )
    }

    selectConto(contoId, skipp) {
        let c = this.state.conti.filter( c => c.idConto === contoId);
        
        this.setState({contoSelezionato: c});
        if(!skipp)
            this.setState({redirectPage :"/registro/movimenti"});
            
    }


    alert = () => {
        if(this.state.alert){
            return (
                <Alert className="navBarContainer" variant={this.state.alert.variant} onClose={() => this.setState({alert:null})} dismissible>
                    <Alert.Heading>{this.state.alert.header}</Alert.Heading>
                    <p>{this.state.alert.text}</p>
                </Alert>
            )

        }        
    }

    setAlert = (alert) => {
        this.setState({alert:alert})
    }

    registroHome = () =>{
        return(
                <Card className="infoBox">
                    <Card.Img className="logo" variant="left" src={logo} alt={logo} thumbnail roundedCircle width="50px"/>
                    <Card.Body>
                        <Card.Title>Registro movimenti bancari</Card.Title>
                        <Card.Text>
                            Utilizzare il menu qui sopra per poter navigare tra le diverse sezioni
                            <ul>
                                <li>Logs : Mostra i log di accesso e operazioni principali</li>
                                <li>Nuovo : Permette di inserire un nuovo movimento</li>
                                <li>Conti : Mostra il saldo dei diversi conti</li>
                                <li>Movimenti : Mostra i movimenti di dettaglio per ogni singolo conto</li>
                                <li>View : Permette di Mostrare/Nascondere i conti meno usati</li>
                            </ul>
                        </Card.Text>

                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Row>Client version : {this.state.app_version}</Row>
                                <Row>Build Date : {this.state.app_build_date}</Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>Server version : {this.state.serverInfo.version}</Row>
                                <Row>Build Date : {this.state.serverInfo.build_date}</Row>
                            </ListGroup.Item>                            
                        </ListGroup>
                    </Card.Body>
                </Card>        
        )
    }

    childrenPages = () => {
        return(
            <Router>
                <Switch>
                    <Route exact path="/registro/inserimento">
                        <Inserisci conti={this.state.conti}
                            setAlert={this.setAlert}
                            addMovimento={this.addMovimento}/>                            
                    </Route>
                    <Route exact path="/registro/conti">
                        <Conti conti={this.state.conti}
                            tipiConto={this.state.tipiConto}
                            selectConto={this.selectConto.bind(this)}/>
                    </Route>
                    <Route exact path="/registro/movimenti">
                        <Movimenti movimenti={this.state.movimenti}
                                contoSelezionato = {this.state.contoSelezionato}
                                conti = {this.state.conti}
                                selectConto={this.selectConto.bind(this)}/>
                    </Route>

                    <Route exact path="/registro/logs">
                        <Logs   logs={this.state.logs}
                                users={this.state.users}/>
                    </Route>

                </Switch>
            </Router>
        )
    }

    navigation = () =>{
        let newPage;
        if(this.state.redirectPage){
            newPage=this.state.redirectPage;
            this.setState({redirectPage:null})       
            return (<Redirect to={newPage} push />)     
        } else {
            return(
                this.state.currentPage === "registro" ? this.registroHome() : this.childrenPages()
            )
        }        

    }

    registro = () =>{
        return(
            <div>

                    <Header tipiConto={this.state.tipiConto}
                            modoInsert={this.state.modoInsert}
                            currentPage={this.state.currentPage}/>    
                    {this.alert()}                

                <Container>
                    {this.navigation()}
                </Container>
                
            </div>
        )
    }

    render(){
        let token = localStorage.getItem('token');

      return(
          <div>
              {(token === undefined) || (token === null) ? this.toLogin() : this.registro()}
          </div>
          
      )
    }
  }
  
  export default Registro;