import React, { Component } from 'react';
import {Button, Row, Col, Card, Container, Form, InputGroup, FormControl} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
registerLocale('it', it)

class Inserisci extends Component {

    state={
        pickedData:new Date()
    }

    formData = {
        data:null,
        importo:null,
        idContoOrigine:null,
        idContoDestinazione:null,
        descrizione:null
    }

    dataForm = (e) => {

        let data = new Date(e);
        
        let Month = (data.getMonth()+1).toString().padStart(2, "0");
        let Day = data.getDate().toString().padStart(2, "0");
        let Hours = data.getHours().toString().padStart(2, "0");
        let Mins = data.getMinutes().toString().padStart(2, "0");
        let Sec = data.getSeconds().toString().padStart(2, "0");
        let myData = data.getFullYear()+Month+Day+Hours+Mins+Sec;        
        console.log(myData)
        
        this.formData.data=myData;
        this.setState({pickedData:data});
    }

    fieldsForm = (e) => {
        if(e.target.name === "importo")
            this.formData[e.target.name]=parseInt(e.target.value);
        else
            this.formData[e.target.name]=e.target.value;

    }


    card = () => {
        return(
                <Card >
                <Card.Body>
                    <Card.Title>Nuovo Movimento</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Inserimento di un nuovo movimento</Card.Subtitle>
                    <Card.Text>
                        {this.form()}
                    </Card.Text>
                </Card.Body>
                </Card>

        )
    }

    conti = () => {
        return(
            this.props.conti.map(c => <option key={c.idConto} value={c.idConto}>{c.NomeConto}</option>)
        )
    }

    form = () => {
        //const [startDate, setStartDate] = useState(new Date());
        //this.formData.data = new Date();

        return (
            <Form>

                <Form.Group controlId="data">
                    <Form.Label>Data</Form.Label><br/>
                    <DatePicker locale="it" selected={this.state.pickedData}  dateFormat="Pp" onChange={this.dataForm}/>  
                </Form.Group>


                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text>Importo €</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="Importo all'euro" name="importo" onChange={this.fieldsForm}/>
                    <InputGroup.Append>
                    <InputGroup.Text>.00</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>


                <Form.Group controlId="contoOrigine">
                    <Form.Label>Conto Origine</Form.Label>
                    <Form.Control as="select" name="idContoOrigine" onChange={this.fieldsForm}>
                        <option key={0} value={0}>..seleziona un conto ...</option>
                        {this.conti()}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="contoDestinazione">
                    <Form.Label>Conto Origine</Form.Label>
                    <Form.Control as="select" name="idContoDestinazione" onChange={this.fieldsForm}>
                        <option key={0} value={0}>..seleziona un conto ...</option>
                        {this.conti()}
                    </Form.Control>
                </Form.Group>


                <Form.Group controlId="Descrizione">
                    <Form.Label>Descrizione</Form.Label>
                    <Form.Control type="text" placeholder="Descrizione" name="descrizione" onChange={this.fieldsForm} />
                </Form.Group>

                <Button variant="primary" onClick={this.submit}>Submit</Button>
                
            </Form>   

        )
    }



    submit = () => {

        let allOK=true;        

        if(!this.formData.data)
            this.dataForm(Date.now())

        if(isNaN(this.formData.importo)){
            this.props.setAlert({variant:"warning", header:"Controllo immissione", text:"Attenzione, sembra che tu non abbia inserito l'importo o che l'importo non sia corretto."})
            allOK=false;
        }
                    

        if(this.formData.idContoOrigine === null || this.formData.idContoOrigine === "0"){
            this.props.setAlert({variant:"warning", header:"Controllo immissione", text:"Attenzione, sembra che tu non abbia selezionato il conto di origine"});
            allOK=false;
        }
            

        if(this.formData.idContoDestinazione === null || this.formData.idContoDestinazione === "0"){
            this.props.setAlert({variant:"warning", header:"Controllo immissione", text:"Attenzione, sembra che tu non abbia selezionato il conto di destinazione"});
            allOK=false;
        }
            
        if(allOK){
            console.log("Vado a sottomettere questi dati");
            console.log(this.formData);
            this.props.addMovimento(this.formData)
            .then(result => this.props.setAlert({variant:"success", header:"Inserimento nuovo Movimento", text:"Il nuovo movimento è stato correttamente inserito con id "+result.data.data.insertId}))
            .catch(error => this.props.setAlert({variant:"danger", header:"Errore inserimento", text:"ATTENZIONE! Movimento non inserito correttamente  "+error}))
            .finally(this.props.swopInserimento)
        } else {
            console.log("Sottomissione interrotta per revisione dati");
        }
        
    }

    render(){
        return(
            <Container>
                {this.card()}
            </Container>
                
            
        )
      }
  }
    
  export default Inserisci;
