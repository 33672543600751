import React, { Component } from 'react';
import {Button, Row, Col, Card, ListGroup, Table, Form, Container} from 'react-bootstrap';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

class Login extends Component {


    render(){
      return(
        <div className="login">
            <Container>
                <h1>Registro Movimenti</h1>

                <Row className="loginButtons">
                    <Col><a href={process.env.REACT_APP_GO_LOGIN}><GoogleLoginButton /></a></Col>
                    <Col><a href={process.env.REACT_APP_FB_LOGIN}><FacebookLoginButton  /></a></Col>
                </Row>

            </Container>
        </div>
      )
    }
  }
  
  export default Login;