import React, { Component } from 'react';
import {Button, Row, Col, Card, Container, Table} from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faMinusCircle, faExclamationCircle , faInfoCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
class Conti extends Component {

    state = {
        showDesc : null
    }

    toggleShowDesc = (idEvento) => {
        if(idEvento === this.state.showDesc)
            this.setState({showDesc : null})
        else
            this.setState({showDesc : idEvento})
    }

    alertLevel = (level, size) => {
        switch(level){
            case 0: return (<FontAwesomeIcon  className="iconMark" icon={faBan} color="purple" size={size}/>)
            case 1: return (<FontAwesomeIcon  className="iconMark" icon={faMinusCircle} color="red" size={size}/>)
            case 2: return (<FontAwesomeIcon  className="iconMark" icon={faExclamationCircle} color="orange" size={size}/>)
            case 3: return (<FontAwesomeIcon  className="iconMark" icon={faInfoCircle} color="green" size={size}/>)
            case 3: return (<FontAwesomeIcon  className="iconMark" icon={faPlusCircle} color="blue" size={size}/>)
        }
    }

    getUser = (userId) => {
        return(this.props.users.filter( u => u.idUtente === userId))
    }

    table = () => {
        return(
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Timestamp</th>
                    <th>type</th>
                    <th>user</th>
                    <th>descrizione</th>
                    </tr>
                </thead>
                <tbody>
                    {this.rows()}
                </tbody>
            </Table>            
        )
    }

    rows = () =>{
        console.log("Evento da esplodere "+this.state.showDesc);
        return(this.props.logs.map(l => {

            if(this.state.showDesc === l.idEvento) {
                return (
                    <tr onClick={() => this.toggleShowDesc(l.idEvento)}>
                        <td colSpan="5">
                        <Card>
                            <Card.Header className="eventNumber">{this.alertLevel(l.type, "1x")} Event # {l.idEvento} </Card.Header>
                            <Card.Body>
                                <Card.Title>Utente {this.getUser(l.idUtente)[0].Nome}</Card.Title>
                                <Card.Text>{l.Descrizione}</Card.Text>
                            </Card.Body>
                        </Card>

                        </td> 
                    </tr>

                )
            } else {
                return(
                    <tr onClick={() => this.toggleShowDesc(l.idEvento)}>
                        <td>{l.idEvento}</td>
                        <td><Moment format="DD/MM/YYYY HH:mm:ss">{l.Timestamp}</Moment></td>
                        <td>{this.alertLevel(l.type, "1x")}</td>
                        <td>{this.getUser(l.idUtente)[0].Nome}</td>
                        <td>{l.Descrizione.substring(0,50)+"..."}</td>            
                    </tr>                
                )
            }
        }))
    }

    render(){
        return(
            <div>
                <h1>Logs</h1>
                    {this.table()}
            </div>
            

        )
    }
}

export default Conti;