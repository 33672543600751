import React, { Component } from 'react';
import {Navbar, Row, Col, NavDropdown, Nav} from 'react-bootstrap';

class Header extends Component {

    tipiConto = () => {
        return(
            this.props.tipiConto.map( tc => {
                let action;
                if(tc.Visibile)
                    action="Nascondi "
                else
                    action="Mostra "
                return(
                    <NavDropdown.Item key={tc.idTipo} onClick={() => this.props.showHideConti(action, tc.idTipo)}>{action + tc.Descrizione}</NavDropdown.Item>
                )
            })
        )

    }

    newMov = () => {
        return(
            this.props.modoInsert ? "Registro" : "Inserisci Movimento"
        )
    }

    navbar = () => {
        return(
                <Navbar expand="lg">
                    <Navbar.Brand href="/registro">Registro movimenti<span className="HeaderPageName">{this.props.currentPage}</span></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#home">Setting</Nav.Link>
                            <Nav.Link href="/registro/logs">Logs</Nav.Link>
                            <Nav.Link href="/registro/inserimento">Nuovo</Nav.Link>
                            <Nav.Link href="/registro/conti">Conti</Nav.Link>
                            <Nav.Link href="/registro/movimenti">Movimenti</Nav.Link>
                            <NavDropdown title="View" id="basic-nav-dropdown">
                                {this.tipiConto()}
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>            
        )
    }

    render(){
      return(
          <div className="navBarContainer">
              {this.navbar()}
          </div>
          
      )
    }
}
  
export default Header;