import React, { Component } from 'react';
import {Button, Row, Col, Card, Container, ListGroup} from 'react-bootstrap';
import Loader from 'react-loader-spinner'

class Conti extends Component {

    conti = (tipo) => {
        let total=0;

        let szConti = this.props.conti
            .filter(c=> c.TipoConto === tipo)
            .map(c => {

                    total += c.Totale;
                    return(
                        <ListGroup.Item key={c.idConto}>
                            <Card onClick={() => this.props.selectConto(c.idConto)}>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Card.Title>{c.NomeConto}</Card.Title>                                                                               
                                        </Col>
                                        <Col>
                                            <Card.Title className="text-right totalBalance">€ {c.Totale}</Card.Title>
                                        </Col>
                                    </Row>
                                    <Row> <Card.Text>{c.Descrizione}</Card.Text></Row>
                                </Card.Body>
                            </Card>
                        </ListGroup.Item>
                    )
                }
            
                        
                        )

        return({snippet:szConti, total:total});
    }

    tipoConto = () => {
        let contiAll = []
        this.props.tipiConto
            .filter(tipo => tipo.Visibile === 1)
            .map ((tipo) => {
            let tipoConto = this.conti(tipo.Descrizione);
            contiAll.push(
                <Col sm={6} className="sezioneConti">
                    <Card key={tipo.idTipo} >
                        <Card.Header style={{backgroundColor : tipo.colore, color : 'white' }} >{tipo.Descrizione}</Card.Header>
                        <ListGroup variant="flush">
                            {tipoConto.snippet}
                        </ListGroup>
                        <Card.Footer>
                            Totale <span className="text-right totalBalance">€{tipoConto.total}</span>
                        </Card.Footer>            
                    </Card>                  
                </Col>

            )
          
        })
        contiAll=<Row>{contiAll}</Row>
        return(contiAll);
    } 

    spinner = () =>{
        return(
            <Loader
               type="Puff"
               color="#00BFFF"
               height={100}
               width={100}
               timeout={3000} //3 secs
       
            />)
    }

    pageConti = () =>{
        return(
            <div >
                {this.tipoConto()}         
            </div>
        )        

    }

    render(){
      return(
          <div>
              {this.props.conti.length>0 ? this.pageConti() : this.spinner() }
          </div>
      )
    }
}
  
export default Conti;